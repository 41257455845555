body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.btn-circle {
  width: 100px;
  height: 100px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  border-radius: 50%;
  border: 2px solid white;
  background-color: rgba(255, 255, 255, 0.75);
  color: black;
}

@media (orientation: portrait) {
  #app {
    display: none !important;
  }
  #warning-message {
    display: block;
  }

  #warning-message-compatibility {
    height: 100vh;
  }

  #warning-message {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    background-color: black;
  }
}

#warning-message-compatibility {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: black;
}

@media (orientation: landscape) {
  #warning-message {
    display: none;
  }
}

.loading-wrapper {
  background-image: url(./intro-background.png);
  background-size: cover;
}

.overlay {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  background-color: rgba(0, 0, 0, 0.5);
}

@media (max-width: 812px) {
  .browser-row img {
    width: 50px;
    height: 50px;
  }

  .btn-circle {
    width: 50px;
    height: 50px;
    padding: 3px 0;
    border-radius: 50%;
  }

  .btn-circle i {
    font-size: 20px !important;
  }
}

.instructions {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 20em;
  width: 40em;
  margin: -10em 0 0 -20em;
  pointer-events: none;
  display: table;
  background: url(./instructions-bg.png) no-repeat;
  background-size: 100%;
  padding-top: 5em;
  -webkit-transition: 1000ms opacity linear;
  -moz-transition: 1000ms opacity linear;
  -ms-transition: 1000ms opacity linear;
  -o-transition: 1000ms opacity linear;
  transition: 1000ms opacity linear;
  opacity: 0;
  z-index: 1;
}

@media only screen and (max-width: 812px) {
  .instructions {
    padding-top: 2.5em;
  }
}

.instructions .inner {
  display: table-row;
  vertical-align: middle;
  height: 100%;
  width: 100%;
  text-align: center;
}

.instructions .copy {
  color: #fff;
  font-size: 2em;

  text-align: center;
}

.instructions.visible {
  opacity: 1;
}

.hand {
  position: relative;
  left: 0em;
  height: 5em;
  background: url(./hand.png) no-repeat center;
  background-size: auto 100%;
  margin-bottom: 2em;
  -webkit-animation: 2000ms handAnimation cubic-bezier(0.445, 0.05, 0.55, 0.95)
    infinite alternate;
  -moz-animation: 2000ms handAnimation cubic-bezier(0.445, 0.05, 0.55, 0.95)
    infinite alternate;
  -ms-animation: 2000ms handAnimation cubic-bezier(0.445, 0.05, 0.55, 0.95)
    infinite alternate;
  -o-animation: 2000ms handAnimation cubic-bezier(0.445, 0.05, 0.55, 0.95)
    infinite alternate;
  animation: 2000ms handAnimation cubic-bezier(0.445, 0.05, 0.55, 0.95) infinite
    alternate;
}

@-webkit-keyframes handAnimation {
  0% {
    -webkit-transform: translateX(3em);
    -moz-transform: translateX(3em);
    -ms-transform: translateX(3em);
    -o-transform: translateX(3em);
    transform: translateX(3em);
  }
  100% {
    -webkit-transform: translateX(-3em);
    -moz-transform: translateX(-3em);
    -ms-transform: translateX(-3em);
    -o-transform: translateX(-3em);
    transform: translateX(-3em);
  }
}

@-moz-keyframes handAnimation {
  0% {
    -webkit-transform: translateX(3em);
    -moz-transform: translateX(3em);
    -ms-transform: translateX(3em);
    -o-transform: translateX(3em);
    transform: translateX(3em);
  }
  100% {
    -webkit-transform: translateX(-3em);
    -moz-transform: translateX(-3em);
    -ms-transform: translateX(-3em);
    -o-transform: translateX(-3em);
    transform: translateX(-3em);
  }
}

@-ms-keyframes handAnimation {
  0% {
    -webkit-transform: translateX(3em);
    -moz-transform: translateX(3em);
    -ms-transform: translateX(3em);
    -o-transform: translateX(3em);
    transform: translateX(3em);
  }
  100% {
    -webkit-transform: translateX(-3em);
    -moz-transform: translateX(-3em);
    -ms-transform: translateX(-3em);
    -o-transform: translateX(-3em);
    transform: translateX(-3em);
  }
}

@keyframes handAnimation {
  0% {
    -webkit-transform: translateX(3em);
    -moz-transform: translateX(3em);
    -ms-transform: translateX(3em);
    -o-transform: translateX(3em);
    transform: translateX(3em);
  }
  100% {
    -webkit-transform: translateX(-3em);
    -moz-transform: translateX(-3em);
    -ms-transform: translateX(-3em);
    -o-transform: translateX(-3em);
    transform: translateX(-3em);
  }
}

.transition {
  -webkit-transition: 1000ms opacity linear;
  -moz-transition: 1000ms opacity linear;
  -ms-transition: 1000ms opacity linear;
  -o-transition: 1000ms opacity linear;
  transition: 1000ms opacity linear;
  opacity: 0;
}

.transition.visible {
  opacity: 1;
}

.transition.visible.fadeout,
.instructions.visible.fadeout {
  opacity: 0;
  -webkit-transition: 500ms opacity linear;
  -moz-transition: 500ms opacity linear;
  -ms-transition: 500ms opacity linear;
  -o-transition: 500ms opacity linear;
  transition: 500ms opacity linear;
}

/* Help panel */
.panel-container {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.panel {
  z-index: 3;
  height: 100%;
  width: 30.5625em;
  background: #fff;
  position: relative;
  color: #383838;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  pointer-events: auto;
}

.panel.visible {
  overflow: auto;
}

.panel.hidden {
  width: 2.0625em;
  pointer-events: none;
}

.panel.hidden > div {
  opacity: 0;
}

.about {
  display: flex;
  flex-direction: column;
  height: 100%;
  text-align: left;
  transition: 500ms cubic-bezier(0.165, 0.84, 0.44, 1) transform;
  transition: 500ms cubic-bezier(0.165, 0.84, 0.44, 1) -webkit-transform;
  transition: 500ms cubic-bezier(0.165, 0.84, 0.44, 1) -moz-transform;
  -webkit-transition: 500ms cubic-bezier(0.165, 0.84, 0.44, 1) transform;
  -webkit-transition: 500ms cubic-bezier(0.165, 0.84, 0.44, 1) -webkit-transform;
  -webkit-transition: 500ms cubic-bezier(0.165, 0.84, 0.44, 1) -moz-transform;
  -moz-transition: 500ms cubic-bezier(0.165, 0.84, 0.44, 1) transform;
  -moz-transition: 500ms cubic-bezier(0.165, 0.84, 0.44, 1) -webkit-transform;
  -moz-transition: 500ms cubic-bezier(0.165, 0.84, 0.44, 1) -moz-transform;
  padding: 3.28125em 3em 0 3.28125em;
  width: 30.5625em;
}

.about {
  -webkit-transform: translate3d(100%, 0, 0);
  -moz-transform: translate3d(100%, 0, 0);
  -ms-transform: translate3d(100%, 0, 0);
  -o-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}

.about .close {
  height: 25px;
  width: 25px;
  background: url("./close.png") no-repeat;
  background-size: 100%;
  cursor: pointer;
}

.about .close:hover {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}

.state-about .about {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.in-app .panel {
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -ms-transform: translateX(100%);
  -o-transform: translateX(100%);
  transform: translateX(100%);
  -webkit-transition: 500ms all cubic-bezier(0.77, 0, 0.175, 1);
  -moz-transition: 500ms all cubic-bezier(0.77, 0, 0.175, 1);
  -ms-transition: 500ms all cubic-bezier(0.77, 0, 0.175, 1);
  -o-transition: 500ms all cubic-bezier(0.77, 0, 0.175, 1);
  transition: 500ms all cubic-bezier(0.77, 0, 0.175, 1);
  pointer-events: none;
}

.in-app .panel.visible {
  pointer-events: auto;
  -webkit-transform: translateX(0%);
  -moz-transform: translateX(0%);
  -ms-transform: translateX(0%);
  -o-transform: translateX(0%);
  transform: translateX(0%);
}

.about .copy {
  margin-top: 20px;
  height: fit-content;
  flex-grow: 1;
}

.about p {
  margin-top: 1em;
  font-size: 0.9375em;
  line-height: 1.5;
}

.about-button {
  position: absolute;
  top: 3em;
  right: 3em;
  height: 2.875em;
  width: 2.875em;
  background: url(./about.png) no-repeat;
  background-size: 100%;
  cursor: pointer;
  z-index: 2;
  opacity: 0;
  -webkit-transition: 200ms opacity linear;
  -moz-transition: 200ms opacity linear;
  -ms-transition: 200ms opacity linear;
  -o-transition: 200ms opacity linear;
  transition: 200ms opacity linear;
  pointer-events: none;
}

.about-button.visible {
  opacity: 1;
  pointer-events: auto;
}

.panel-container footer {
  display: flex;
  margin-bottom: 20px;
}

.credits p {
  font-size: 14px;
  font-style: italic;
}

.transition.hidden {
  visibility: hidden;
}
